/* Basic container and header styles */
@import url("https://fonts.googleapis.com/css2?family=Trispace:wght@100..800&display=swap");

.selected {
  /* background-color: #f0f0f5; Or any other highlight color */
  border-radius: 10px;
  border: 2px solid #f0f0f5;
  /* font-weight: 500; Or any other style you prefer */
}

.container {
    width: 90%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 40px 20px;
    font-family: 'Courier New', Courier, monospace;
    color: #333;
  }
  
  header {
    text-align: center;
    margin-bottom: 40px;
  }
  
  header h1 {
    font-size: 2rem;
    letter-spacing: 3px;
    

  }
  
  header h2 {
    font-size: 1.5rem;
    margin-top: 10px;
    letter-spacing: 2px;
  }
  
.content{
    display: flex;
    width: 100%;
    justify-content: space-evenly;
}

.sidebar{
    min-width: 225px;
    max-width: 275px;
    margin: 0px 10px 0px 0px;
    border-radius: 10px;
      position: sticky; /* Makes the sidebar sticky */
  top: 0; /* Sticks the sidebar to the top when scrolled */
  align-self: flex-start; /* Ensures correct sticky behavior within a flex container */
}
  /* Person section styling */
  .person-section {
    display: flex;
    flex-direction: column;
    grid-template-columns: 1fr;
    gap: 40px;
  }
  
  .person {
    /* background-color: #f9f9f9; */
    padding: 20px;
    border-radius: 10px;
    border: 1px black solid;
  }
  
  .person h3 {
    font-size: 1.2rem;
    margin-bottom: 10px;
    text-align: left;
  }
  
  .person .date {
    font-size: 0.9rem;
    color: #777;
    margin-bottom: 20px;
    text-align: left;
  }
  
  .person .description {
    font-size: 1rem;
    line-height: 1.6;
    text-align: left;
  }
  
  @media (max-width: 768px) {
    .container {
      padding: 20px;
    }
  
    .person-section {
      grid-template-columns: 1fr;
    }
  }
  

  /* Sidebar styles */
.sidebar {
  width: 25%;
  padding: 15px;
  /* background-color: #f4f4f4; */
  /* border: 1px black solid; */
}

.sidebar h3 {
  margin-bottom: 10px;
}

.sidebar ul {
  list-style-type: none;
  padding: 0;
}

.sidebar ul li {
  margin-bottom: 10px;
}

.sidebar ul li a {
  text-decoration: none;
  color: #007bff;
}

.sidebar ul li a:hover {
  text-decoration: underline;
}
