/* NavBar Styles */
@import url("https://fonts.googleapis.com/css2?family=Trispace:wght@100..800&display=swap");

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 10px 10px 10px;
  flex-direction: column;
  font-family: "Trispace", sans-serif;
  font-optical-sizing: auto;
  font-weight: 300;
  font-style: normal;
  font-variation-settings: "wdth" 100;
}

.navbar-header {
  /* font-family: 'Courier New', Courier, monospace; */
  font-size: 1rem;
  margin-bottom: 10px;
}

.navbar-menu {
  display: flex;
  list-style: none;
  gap: 20px;
  /* font-family: 'Courier New', Courier, monospace; */
  font-size: 0.8rem;
  margin: 0;
  padding: 0;
  align-items: center;
  justify-content: space-between;
}

.navbar-item {
  color: inherit;
  text-decoration: none;
}

.navbar-item:hover {
  color: #6c00a7;
  text-decoration: none;
  cursor: pointer;
}

.navbar-menu li {
  cursor: pointer;
}

/* Responsive Styling */
@media (max-width: 768px) {
  .navbar-menu {
    flex-direction: column;
    text-align: center;
  }
}
