/* Container */
.container {
  text-align: center;
  margin: 0 auto;
  padding: 20px;
  max-width: 1200px;
}

.link {
  color: inherit;
  text-decoration: none !important;
}

.link:hover {
  color: #6c00a7;
  text-decoration: none !important;
  cursor: pointer;
}
.font-trispace {
  font-family: "Trispace", sans-serif;
  font-optical-sizing: auto;
  font-weight: 300;
  font-style: normal;
  font-variation-settings: "wdth" 100;
}

.title-rkb{
  margin-bottom: 10px;
  padding: 4px;
  padding-bottom: 0px;
  cursor: pointer;
}

.title-rkb:hover{
  color: #6c00a7;
}
header {
  margin-bottom: 20px;
}

.grid-layout {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 20px;
  padding: 20px;
}

.grid-item {
  border: 1px solid #eaeaea;
  padding: 20px;
  background-color: #fff;
  text-align: center;
}

.grid-item img {
  width: 50px;
  height: auto;
}

h3 {
  margin-top: 10px;
  font-size: 1.2rem;
}

p {
  font-size: 0.9rem;
  color: #333;
}

/* Footer */
footer {
  margin-top: 20px;
}

.social-icons {
  font-size: 2rem;
}

.social-icons i {
  margin: 0 10px;
  color: #333;
}

/* Responsive Design */
@media (max-width: 768px) {
  .grid-layout {
    grid-template-columns: 1fr;
  }
}

