.poem-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* 3 columns */
    gap: 20px; /* Space between poems */
    padding: 20px;
  }
  
  .poem-card {
    padding: 15px;
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    text-align: center;
    display: flex;              /* Enable flexbox layout */
    align-items: flex-start;    /* Align items to the top */
    margin-bottom: 20px;   
    align-items: center;
  }
  
  .poem-card h3 {
    font-size: 1rem;
    color: #333;

  }
  
  .poem-card p {
    font-size: 1rem;
    color: #666;
    line-height: 1.5;
  }
  
  .poem-link:link {
    color: blue;
  }
  
  .poem-link:visited {
    color: blue;
  }

  .poem-image {
    width: 200px;             /* Set desired image width */
    height: auto;             /* Maintain aspect ratio */
    margin-right: 10px;      /* Add space between image and text */
  }